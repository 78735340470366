import $ from 'jquery'
import { notifyError } from '@/services/notify'

export const methods = {
  async reload() {
    this.loading = true
    this.checkboxGroup = []
    this.checkboxTextGroup = []
    this.radioMailAddressTypeGroup = 'property'

    if (
      this.selectedSubscriptionNotification &&
      this.selectedSubscriptionNotification.notificationType
    ) {
      if (this.isDebug == true)
        console.debug(
          'this.selectedSubscriptionNotification=' +
            JSON.stringify(this.selectedSubscriptionNotification)
        )
      this.formData.AlertType = this.selectedSubscriptionNotification.notificationType.name
    }

    this.$store
      .dispatch('alertSubscription/getNotificationDeliveryList', {
        supportsManagementAlerts: true
      })
      .then(() => {
        if (
          this.selectedSubscriptionNotification &&
          this.selectedSubscriptionNotification.notificationType
        ) {
          try {
            if (
              this.selectedSubscriptionNotification.notificationDeliveryNameCsv &&
              this.selectedSubscriptionNotification.notificationDeliveryNameCsv != undefined &&
              this.selectedSubscriptionNotification.notificationDeliveryNameCsv.length > 0
            ) {
              let split = this.selectedSubscriptionNotification.notificationDeliveryNameCsv.split(
                ','
              )
              for (let i = 0; i < split.length; i++) {
                var checked = split[i].trim()
                if (this.isDebug == true) console.debug(checked)

                if (checked.length > 0) {
                  this.checkboxGroup.push(checked)

                  if (checked == 'text') {
                    this.showTextChecks = true

                    if (
                      this.selectedSubscriptionNotification.notificationSubscriptionList &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList !=
                        undefined &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results !=
                        undefined &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results
                        .length > 0
                    ) {
                      console.log(
                        'notificationSubscription for text=' +
                          JSON.stringify(
                            this.selectedSubscriptionNotification.notificationSubscriptionList.results.filter(
                              f => f.deliveryType == 'text'
                            )
                          )
                      )
                      const subscribedList = this.selectedSubscriptionNotification.notificationSubscriptionList.results.filter(
                        f => f.deliveryType == 'text'
                      )[0]
                      if (
                        subscribedList &&
                        subscribedList != undefined &&
                        subscribedList.subscribedTextOwnerContacts &&
                        subscribedList.subscribedTextOwnerContacts != undefined &&
                        subscribedList.subscribedTextOwnerContacts.length > 0
                      ) {
                        const persistedTextIds = this.removeDuplicates(
                          subscribedList.subscribedTextOwnerContacts.map(a => a.ownerContactID)
                        )
                        if (
                          persistedTextIds &&
                          persistedTextIds != undefined &&
                          persistedTextIds.length > 0
                        ) {
                          console.log('persistedTextIds=' + JSON.stringify(persistedTextIds))
                          this.checkboxTextGroup = persistedTextIds
                        }
                      }
                    }
                  } else if (checked == 'email') {
                    this.showEmailChecks = true

                    if (
                      this.selectedSubscriptionNotification.notificationSubscriptionList &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList !=
                        undefined &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results !=
                        undefined &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results
                        .length > 0
                    ) {
                      console.log(
                        'notificationSubscription for email=' +
                          JSON.stringify(
                            this.selectedSubscriptionNotification.notificationSubscriptionList.results.filter(
                              f => f.deliveryType == 'email'
                            )
                          )
                      )
                      const subscribedList = this.selectedSubscriptionNotification.notificationSubscriptionList.results.filter(
                        f => f.deliveryType == 'email'
                      )[0]
                      if (
                        subscribedList &&
                        subscribedList != undefined &&
                        subscribedList.subscribedEmailOwnerContacts &&
                        subscribedList.subscribedEmailOwnerContacts != undefined &&
                        subscribedList.subscribedEmailOwnerContacts.length > 0
                      ) {
                        const persistedEmailIds = this.removeDuplicates(
                          subscribedList.subscribedEmailOwnerContacts.map(a => a.ownerContactID)
                        )
                        if (
                          persistedEmailIds &&
                          persistedEmailIds != undefined &&
                          persistedEmailIds.length > 0
                        ) {
                          console.log('persistedEmails=' + JSON.stringify(persistedEmailIds))
                          this.checkboxEmailGroup = persistedEmailIds
                        }
                      }
                    }
                  } else if (checked == 'mail') {
                    this.showMailRadios = true
                    if (
                      this.selectedSubscriptionNotification.notificationSubscriptionList &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList !=
                        undefined &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results !=
                        undefined &&
                      this.selectedSubscriptionNotification.notificationSubscriptionList.results
                        .length > 0
                    ) {
                      const persistedAddressType = this.selectedSubscriptionNotification.notificationSubscriptionList.results.filter(
                        f => f.deliveryType == 'mail'
                      )[0].mailAddressType
                      this.radioMailAddressTypeGroup =
                        persistedAddressType && persistedAddressType != undefined
                          ? persistedAddressType
                          : 'property'
                    }
                  }
                }
              }
            }
          } catch (e) {
            console.debug(e)
          }
        }
      })

    this.loading = false
  },

  removeDuplicates(removeDuplicateList) {
    var result = []
    removeDuplicateList.forEach(function(item) {
      if (result.indexOf(item) < 0) {
        result.push(item)
      }
    })
    return result
  },

  open() {
    this.formData = {
      notificationType: ''
    }
    this.isOpen = true
    this.loading = false
  },

  processHideShowChildren(item) {
    if (item) {
      if (item.deliveryType == 'email') {
        if (this.showEmailChecks == true) {
          this.showEmailChecks = false
        } else {
          this.showEmailChecks = true
        }
      } else if (item.deliveryType == 'text') {
        if (this.showTextChecks == true) {
          this.showTextChecks = false
        } else {
          this.showTextChecks = true
        }
      } else if (item.deliveryType == 'mail') {
        if (this.showMailRadios == true) {
          this.showMailRadios = false
        } else {
          this.showMailRadios = true
        }
      }
    }
  },

  getPayload() {
    if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

    const payload = {
      notificationTypeID:
        this.selectedSubscriptionNotification.notificationType &&
        this.selectedSubscriptionNotification.notificationType != undefined
          ? this.selectedSubscriptionNotification.notificationType.notificationTypeID
          : 0,
      hoaID: this.hoaId,
      notificationDeliveryTypes: this.checkboxGroup
    }

    if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

    return payload
  },

  async onFormSubmit() {
    if (
      !this.formData ||
      (this.formData == undefined &&
        this.selectedSubscriptionNotification &&
        this.selectedSubscriptionNotification != undefined &&
        this.selectedSubscriptionNotification.notificationType &&
        this.selectedSubscriptionNotification.notificationType != undefined)
    ) {
      notifyError('There was a problem submitting your subscription notifications.')
      return
    }

    try {
      const payload = this.getPayload()

      let validationMessages = ''

      if (this.showEmailChecks == true) {
        let selectedEmailOwnerContacts = []

        if (
          this.checkboxEmailGroup &&
          this.checkboxEmailGroup != undefined &&
          this.checkboxEmailGroup.length > 0 &&
          this.selectedSubscriptionNotification &&
          this.selectedSubscriptionNotification != undefined &&
          this.selectedSubscriptionNotification.supportedDeliveryNameCsvs &&
          this.selectedSubscriptionNotification.supportedDeliveryNameCsvs != undefined
        ) {
          Array.from(this.checkboxEmailGroup).forEach(e => {
            const emailDelivery = this.selectedSubscriptionNotification.supportedDeliveryNameCsvs.filter(
              f => f.deliveryType == 'email'
            )[0]

            if (emailDelivery && emailDelivery.ownerContacts) {
              const filteredChecked = emailDelivery.ownerContacts.filter(
                f => f.ownerContactID == e
              )[0]

              if (this.isDebug == true)
                console.debug(
                  'this.checkboxEmailGroup filteredChecked=' + filteredChecked.emailAddressOne
                )

              if (
                (filteredChecked && filteredChecked.emailAddressOne) ||
                filteredChecked.emailAddressTwo ||
                filteredChecked.emailAddressThree
              ) {
                selectedEmailOwnerContacts.push(e)
              }
            }
          })
          payload.homeEmailOwnerContacts = selectedEmailOwnerContacts
        }

        if (selectedEmailOwnerContacts.length == 0) {
          validationMessages =
            'An email can only be sent if the selected contact has an email address.'
        }
      }

      if (this.showTextChecks == true) {
        let selectedTextOwnerContacts = []

        if (
          this.checkboxTextGroup &&
          this.checkboxTextGroup != undefined &&
          this.checkboxTextGroup.length > 0 &&
          this.selectedSubscriptionNotification &&
          this.selectedSubscriptionNotification != undefined &&
          this.selectedSubscriptionNotification.supportedDeliveryNameCsvs &&
          this.selectedSubscriptionNotification.supportedDeliveryNameCsvs != undefined
        ) {
          Array.from(this.checkboxTextGroup).forEach(e => {
            const textDelivery = this.selectedSubscriptionNotification.supportedDeliveryNameCsvs.filter(
              f => f.deliveryType == 'text'
            )[0]

            if (textDelivery && textDelivery.ownerContacts) {
              const filteredChecked2 = textDelivery.ownerContacts.filter(
                f => f.ownerContactID == e
              )[0]

              if (this.isDebug == true)
                console.debug(
                  'this.checkboxTextGroup filteredChecked2=' + filteredChecked2.cellPhone
                )

              if (filteredChecked2 && filteredChecked2.cellPhone) {
                selectedTextOwnerContacts.push(e)

                if (this.isDebug == true)
                  console.debug('this.checkboxTextGroup e=' + JSON.stringify(e))
              }
            }
          })
          payload.homeTextOwnerContacts = selectedTextOwnerContacts
        }

        if (selectedTextOwnerContacts.length == 0) {
          validationMessages +=
            '<br />A text message can only be sent if the selected contact has a cell number.'
        }
      }

      if (validationMessages.length > 0 && (this.showEmailChecks == true || this.showTextChecks)) {
        notifyError(validationMessages)
        return
      }

      if (this.showMailRadios == true) {
        payload.mailAddressType = this.radioMailAddressTypeGroup
      }

      if (this.isDebug == true) console.debug('submitted payload =' + JSON.stringify(payload))

      this.loading = true

      this.$store.dispatch('alertSubscription/processNotificationSubscriptions', {
        payload: payload,
        done: () => {
          // auto-close modal
          this.$emit('update:toggle', (this.toggle = false))
          $('#clickclose').click()
          this.loading = false
        }
      })
    } catch (e) {
      this.loading = false

      notifyError('There was a problem processing your subscription notifications.')
    }
    this.loading = false
  }
}
