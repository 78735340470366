import moment from 'moment'

let DEFAULT_FORM_VALUES_INIT = {
  fullName: '',
  emailAddress: '',
  phone: ''
}

export const data = () => ({
  formComplete: false,
  formData: {
    ...DEFAULT_FORM_VALUES_INIT
  },
  toggle: false,
  loading: true,
  isDebug: true,
  profile: null,
  imageContent: null,
  hoaList: [{}],
  formatDateFn: function(value) {
    return value && value != undefined
      ? moment(value.toString(), 'YYYY/MM/DD HH:mm:ss')
          .format('MM-DD-YYYY')
          .toString()
      : ''
  }
})
