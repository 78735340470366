import { profileImageStore } from '@/services/UserProfileImage/store'
import { notifyError, notifyMessage } from '@/services/notify'
import $ from 'jquery'

export const methods = {
  async reload() {
    this.loading = true

    await profileImageStore.dispatch('getUserProfileId').then(({ result }) => {
      if (result) {
        console.debug('result=' + JSON.stringify(result))
        this.profileImageDocumentID = result.profileImageDocumentID
      }
    })

    if (
      this.profileImageDocument &&
      this.profileImageDocument !== undefined &&
      this.profileImageDocument.profileImageDocumentID &&
      this.profileImageDocument.profileImageDocumentID != undefined &&
      this.profileImageDocumentID
    ) {
      this.attachment = true
      this.loadCurrentDocument()

      console.debug('profileImageDocument=' + JSON.stringify(this.profileImageDocument))

      //Update
      this.title = 'Save'

      this.loading = false
    } else {
      this.title = 'Add'
      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.isOpen = true
    this.loading = false
  },

  async replaceFile() {
    if (
      this.profileImageDocument &&
      this.profileImageDocument != undefined &&
      this.profileImageDocument.profileImageDocumentID > 0
    ) {
      const payload = {
        file: this.file
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      await profileImageStore.dispatch('uploadProfileImage', {
        payload: payload,
        done: ({ returnedFile }) => {
          if (returnedFile) {
            this.closeModal()

            notifyMessage(`Successfully uploaded the image.`)
          } else {
            notifyError('There was a problem uploading this image.')
          }
        }
      })

      loadingComponent.close()
    }
  },

  async loadCurrentDocument() {
    if (
      !this.profileImageDocument ||
      !this.profileImageDocument.profileImageDocumentID ||
      this.profileImageDocument.profileImageDocumentID <= 0
    ) {
      return
    }

    try {
      const params = {
        asBase64: true
      }

      await profileImageStore.dispatch('getProfileImageFile', {
        params: params,
        done: ({ details }) => {
          if (details) {
            if (this.isDebug == true)
              console.debug(
                ' - params' + JSON.stringify(params) + ' - this.getProfileImageFile=' + details
              )

            this.base64pdf = null
            this.imageContent = details
          }
        }
      })
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
    }
  },

  closeModal() {
    this.$emit('update:toggle', (this.toggle = !this.toggle))

    // auto-close modal
    $('#clickclose').click()
  },

  async submitFile() {
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      const payload = {
        file: this.file
      }

      if (this.attachment == false) {
        await profileImageStore.dispatch('uploadProfileImage', {
          payload: payload,
          done: ({ returnedFile }) => {
            if (returnedFile) {
              this.closeModal()

              notifyMessage(`Successfully uploaded the image.`)
            } else {
              notifyError('There was a problem uploading this image.')
            }
          }
        })
      }
    } catch (e) {
      loadingComponent.close()
      notifyError(e)
    }
    this.loading = false
    loadingComponent.close()
  },

  zoom(direction) {
    const amt = Math.sqrt(2)
    if (direction > 0) {
      this.scale *= amt
    } else {
      this.scale /= amt
    }

    // constrain to min/max
    this.scale = Math.max(0.5, Math.min(4, this.scale))
  }
}
