var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"notification-modal":""}},[_c('div',{staticClass:"container"},[(_vm.selectedSubscriptionNotification && _vm.selectedSubscriptionNotification != undefined)?_c('div',{staticClass:"box is-12"},[_c('div',{staticClass:"is-size-4"},[_vm._v("Edit Notification Subscription")]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onFormSubmit)}}},[_c('fieldset',[_c('div',{staticClass:"column is-12"},[_c('valid-input',{staticClass:"is-small",attrs:{"name":"Notification Type","disabled":"true","label":"Notification Type","type":"text","vid":"AlertType","placeholder":"Notification Type","spellcheck":"true","aria-label":"Notification Type","rules":"required"},model:{value:(_vm.formData.AlertType),callback:function ($$v) {_vm.$set(_vm.formData, "AlertType", $$v)},expression:"formData.AlertType"}})],1),_c('b-field',{staticClass:"pl-3 pt-4",attrs:{"label":"Notification Delivery Types"}},[(
                    _vm.selectedSubscriptionNotification &&
                      _vm.selectedSubscriptionNotification != undefined &&
                      _vm.selectedSubscriptionNotification.supportedDeliveryNameCsvs &&
                      _vm.selectedSubscriptionNotification.supportedDeliveryNameCsvs != undefined
                  )?_c('div',{staticClass:"column is-12"},_vm._l((_vm.selectedSubscriptionNotification.supportedDeliveryNameCsvs),function(item,index){return _c('div',{key:index,staticClass:"column is-12"},[_c('div',{staticClass:"font-bold subtitle is-5"},[_c('b-checkbox',{attrs:{"disabled":item.isRequired,"native-value":item.deliveryType},nativeOn:{"click":function($event){return _vm.processHideShowChildren(item)}},model:{value:(_vm.checkboxGroup),callback:function ($$v) {_vm.checkboxGroup=$$v},expression:"checkboxGroup"}},[_vm._v(" "+_vm._s(item.deliveryType)+" ")])],1),(
                        item.ownerContacts &&
                          item.ownerContacts != undefined &&
                          item.deliveryType == 'email' &&
                          _vm.showEmailChecks == true
                      )?_c('div',_vm._l((item.ownerContacts),function(item,index){return _c('div',{key:index,staticClass:"column is-12 pl-5"},[_c('b-checkbox',{attrs:{"native-value":item.ownerContactID},model:{value:(_vm.checkboxEmailGroup),callback:function ($$v) {_vm.checkboxEmailGroup=$$v},expression:"checkboxEmailGroup"}},[_vm._v(" "+_vm._s(_vm.processEmailAddresssFn(item))+" ")])],1)}),0):_vm._e(),(
                        item.ownerContacts &&
                          item.ownerContacts != undefined &&
                          item.deliveryType == 'text' &&
                          _vm.showTextChecks == true
                      )?_c('div',_vm._l((item.ownerContacts),function(item,index){return _c('div',{key:index,staticClass:"column is-12 pl-5"},[_c('b-checkbox',{attrs:{"native-value":item.ownerContactID},model:{value:(_vm.checkboxTextGroup),callback:function ($$v) {_vm.checkboxTextGroup=$$v},expression:"checkboxTextGroup"}},[_vm._v(" "+_vm._s(_vm.processTextAddresssFn(item))+" ")])],1)}),0):_vm._e(),(
                        item.propertyAddress &&
                          item.propertyAddress != undefined &&
                          item.deliveryType == 'mail' &&
                          _vm.showMailRadios == true
                      )?_c('div',[(item.propertyAddress && item.propertyAddress != undefined)?_c('div',{staticClass:"column is-12 pl-5"},[_c('b-radio',{attrs:{"name":"name","native-value":"property"},model:{value:(_vm.radioMailAddressTypeGroup),callback:function ($$v) {_vm.radioMailAddressTypeGroup=$$v},expression:"radioMailAddressTypeGroup"}},[_vm._v(" "+_vm._s(("Property Address: " + (item.propertyAddress)))+" ")])],1):_vm._e(),(item.mailingAddress && item.mailingAddress != undefined)?_c('div',{staticClass:"column is-12 pl-5"},[_c('b-radio',{attrs:{"name":"name","native-value":"mailing"},model:{value:(_vm.radioMailAddressTypeGroup),callback:function ($$v) {_vm.radioMailAddressTypeGroup=$$v},expression:"radioMailAddressTypeGroup"}},[_vm._v(" "+_vm._s(("Mailing Address: " + (item.mailingAddress)))+" ")])],1):_vm._e()]):_vm._e()])}),0):_vm._e()]),_c('div',{staticClass:"pb-5"},[_c('button',{staticClass:"button is-link is-light",attrs:{"type":"submit","disabled":_vm.loading}},[_vm._v(" Save ")])])],1),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}],null,false,1309873005)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }