export function data() {
  return {
    formComplete: false,
    formData: {
      notificationType: ''
    },
    isOpen: false,
    loading: false,
    isDebug: true,
    checkboxGroup: [],
    checkboxEmailGroup: [],
    checkboxTextGroup: [],
    checkboxGroupCodeNames: [],
    radioMailAddressTypeGroup: 'property',
    showEmailChecks: false,
    showTextChecks: false,
    showMailRadios: false,
    processEmailAddresssFn: function(values) {
      if (values && values != undefined) {
        let content = ''

        const firstName = values.firstName ? values.firstName : ''
        const lastName = values.lastName ? values.lastName : ''
        const emailAddressOne = values.emailAddressOne ? values.emailAddressOne : ''
        const emailAddressTwo = values.emailAddressTwo ? values.emailAddressTwo : ''
        const emailAddressThree = values.emailAddressThree ? values.emailAddressThree : ''

        if (firstName && firstName.length > 0) {
          content = firstName
        }

        if (lastName && lastName.length > 0) {
          content = `${content} ${lastName}`
        }

        if (!values.emailAddressOne && !values.emailAddressTwo && !values.emailAddressThree) {
          content = `${content}: <No Email Address on File>`
        } else {
          if (emailAddressOne && emailAddressOne.length > 0) {
            content = `${content}: ${emailAddressOne}`
          }

          if (emailAddressTwo && emailAddressTwo.length > 0) {
            content = `${content}, ${emailAddressTwo}`
          }

          if (emailAddressThree && emailAddressThree.length > 0) {
            content = `${content}, ${emailAddressThree}`
          }
        }
        return values != null ? content : ''
      }
    },
    processTextAddresssFn: function(values) {
      let content = ''

      if (values && values != undefined) {
        const firstName = values.firstName ? values.firstName : ''
        const lastName = values.lastName ? values.lastName : ''
        const mobileNumber = values.cellPhone ? values.cellPhone : ''

        if (firstName && firstName.length > 0) {
          content = firstName
        }

        if (lastName && lastName.length > 0) {
          content = `${content} ${lastName}`
        }

        if (!mobileNumber) {
          content = `${content}: <No Mobile Phone on File>`
        } else {
          content = `${content}: ${mobileNumber}`
        }
      }
      return values != null ? content : ''
    }
  }
}
