<template>
  <div notification-modal>
    <div class="container">
      <div
        class="box is-12"
        v-if="selectedSubscriptionNotification && selectedSubscriptionNotification != undefined"
      >
        <div class="is-size-4">Edit Notification Subscription</div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
              <fieldset>
                <div class="column is-12">
                  <valid-input
                    name="Notification Type"
                    disabled="true"
                    label="Notification Type"
                    type="text"
                    vid="AlertType"
                    placeholder="Notification Type"
                    spellcheck="true"
                    aria-label="Notification Type"
                    rules="required"
                    class="is-small"
                    v-model="formData.AlertType"
                  />
                </div>
                <b-field class="pl-3 pt-4" label="Notification Delivery Types">
                  <div
                    class="column is-12"
                    v-if="
                      selectedSubscriptionNotification &&
                        selectedSubscriptionNotification != undefined &&
                        selectedSubscriptionNotification.supportedDeliveryNameCsvs &&
                        selectedSubscriptionNotification.supportedDeliveryNameCsvs != undefined
                    "
                  >
                    <div
                      class="column is-12"
                      v-for="(item,
                      index) in selectedSubscriptionNotification.supportedDeliveryNameCsvs"
                      :key="index"
                    >
                      <div class="font-bold subtitle is-5">
                        <b-checkbox
                          :disabled="item.isRequired"
                          v-model="checkboxGroup"
                          :native-value="item.deliveryType"
                          @click.native="processHideShowChildren(item)"
                        >
                          {{ item.deliveryType }}
                        </b-checkbox>
                      </div>
                      <div
                        v-if="
                          item.ownerContacts &&
                            item.ownerContacts != undefined &&
                            item.deliveryType == 'email' &&
                            showEmailChecks == true
                        "
                      >
                        <div
                          class="column is-12 pl-5"
                          v-for="(item, index) in item.ownerContacts"
                          :key="index"
                        >
                          <b-checkbox
                            v-model="checkboxEmailGroup"
                            :native-value="item.ownerContactID"
                          >
                            {{ processEmailAddresssFn(item) }}
                          </b-checkbox>
                        </div>
                      </div>
                      <div
                        v-if="
                          item.ownerContacts &&
                            item.ownerContacts != undefined &&
                            item.deliveryType == 'text' &&
                            showTextChecks == true
                        "
                      >
                        <div
                          class="column is-12 pl-5"
                          v-for="(item, index) in item.ownerContacts"
                          :key="index"
                        >
                          <b-checkbox
                            v-model="checkboxTextGroup"
                            :native-value="item.ownerContactID"
                          >
                            {{ processTextAddresssFn(item) }}
                          </b-checkbox>
                        </div>
                      </div>
                      <div
                        v-if="
                          item.propertyAddress &&
                            item.propertyAddress != undefined &&
                            item.deliveryType == 'mail' &&
                            showMailRadios == true
                        "
                      >
                        <div
                          class="column is-12 pl-5"
                          v-if="item.propertyAddress && item.propertyAddress != undefined"
                        >
                          <b-radio
                            v-model="radioMailAddressTypeGroup"
                            name="name"
                            native-value="property"
                          >
                            {{ `Property Address: ${item.propertyAddress}` }}
                          </b-radio>
                        </div>
                        <div
                          class="column is-12 pl-5"
                          v-if="item.mailingAddress && item.mailingAddress != undefined"
                        >
                          <b-radio
                            v-model="radioMailAddressTypeGroup"
                            name="name"
                            native-value="mailing"
                          >
                            {{ `Mailing Address: ${item.mailingAddress}` }}
                          </b-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-field>
                <div class="pb-5">
                  <button type="submit" :disabled="loading" class="button is-link is-light">
                    Save
                  </button>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'

import { methods } from './keys/methods'
import { data } from './keys/data'

// components
import ValidInput from '@/components/inputs/ValidInput'

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'NotificationSubscriptionModal',

  props: {
    selectedSubscriptionNotification: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {
    this.reload()
  },

  data,

  methods
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>
