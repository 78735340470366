<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('profile.title')">
    <div class="notification headerColor box is-4" v-if="authUser">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(confirmUpdate)" class="form">
            <fieldset>
              <div class="form-grid">
                <div>
                  <div v-if="imageContent">
                    <div class="pl-2">
                      <img
                        height="50%"
                        width="50%"
                        style="min-height: 200px; min-width: 200px;"
                        :src="imageContent"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div class="pl-3">
                      <img
                        height="100%"
                        width="100%"
                        style="min-height: 200px;"
                        :src="require('@/assets/images/defaultProfileImage.svg')"
                      />
                    </div>
                  </div>
                  <div class="pt-3 pb-5 pl-4">
                    <b-button
                      v-if="!imageContent"
                      @click.prevent="saveProfileImage"
                      type="button"
                      style="margin-left: -5px !important;"
                      class="is-primary is-rounded"
                    >
                      Add Profile Image
                    </b-button>
                    <b-button
                      v-else
                      @click.prevent="saveProfileImage"
                      type="button"
                      style="margin-left: -5px !important;"
                      class="is-primary is-rounded"
                    >
                      Update
                    </b-button>
                    <span class="pl-3">&nbsp;</span>
                    <b-button
                      v-if="imageContent"
                      @click.prevent="confirmRemoval"
                      type="button"
                      class="is-primary is-rounded"
                    >
                      Remove
                    </b-button>
                  </div>
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <span style="white-space: nowrap">
                    <valid-input
                      label="Full Name"
                      name="FullName"
                      type="text"
                      vid="fullName"
                      placeholder="Full Name"
                      spellcheck="true"
                      maxlength="50"
                      rules="required|max:50"
                      v-model="formData.fullName"
                    />
                  </span>
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    label="Email Address"
                    name="EmailAddress"
                    maxlength="100"
                    type="email"
                    vid="emailAddress"
                    placeholder="Email Address"
                    spellcheck="true"
                    rules="required|max:100"
                    v-model="formData.emailAddress"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    label="Phone"
                    name="Phone"
                    maxlength="20"
                    type="phone"
                    vid="phone"
                    placeholder="Phone"
                    spellcheck="true"
                    rules="max:20"
                    v-model="formData.phone"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <button type="submit" class="button is-primary is-rounded" :disabled="loading">
                Save
              </button>
            </fieldset>
          </form>
        </transition>
      </ValidationObserver>
    </div>
    <Modal :toggle.sync="toggle">
      <ProfileImageModal :toggle="toggle" :profileImageDocument="this.profile"> </ProfileImageModal>
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapState, mapGetters } from 'vuex'
import ValidInput from '@/components/inputs/ValidInput'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProfileImageModal from './component/ProfileImageModal'
import Modal from '@/components/Modal'

import { data } from './keys/data'
import { methods } from './keys/methods'

let DEFAULT_FORM_VALUES = {
  fullName: '',
  emailAddress: '',
  phone: ''
}

export default {
  components: {
    PageContentLayoutOnly,
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    Modal,
    ProfileImageModal
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  mounted() {
    this.loading = true

    this.refreshUserProfile()

    this.formData = { ...DEFAULT_FORM_VALUES }
    if (this.$refs.form) {
      this.$refs.form.reset()
    }

    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    async toggle() {
      if (this.toggle == false) {
        this.toggle = false
        this.reload()
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: { profile: { title: 'Profile' } }
    }
  }
}
</script>
