var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  isQuery: false,
  loading: false,
  selectedSubscriptionNotification: null,
  isFetching: false,
  data: [],
  subscriptionNotifications: [],

  filters: {
    show: false
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'notificationType',
      label: 'Notification Type',
      width: '45%',
      sortable: true,
      searchable: true
    },
    {
      field: 'notificationDeliveryType',
      label: 'Notification Delivery Type',
      width: '45%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    formRow: {
      padding: '10px',
      whiteSpace: 'nowrap'
    },
    filterButton: {
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
