import Button from '@/components/buttons/Button'

import _get from 'lodash/get'
import { mapActions } from 'vuex'

export const methods = {
  ...mapActions('alertSubscription', ['getSubscriptionNotificationList']),

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  confirmDeleteNotificationSubscription(notificationSubscriptionID, notificationType) {
    this.$buefy.dialog.confirm({
      title: 'Deleting G/L Notification Subscription',
      message: `Are you sure you want to <b>delete</b> the G/L Notification Subscription for "${notificationType}"?`,
      confirmText: 'Delete G/L Notification Subscription',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteNotificationSubscription(notificationSubscriptionID)
    })
  },

  determineSubscriptionNotifications() {
    if (this.isDebug == true) console.debug('determineSubscriptionNotifications()...')

    if (this.subscriptionNotificationList) {
      this.subscriptionNotifications = this.subscriptionNotificationList.map(i => {
        return {
          notificationType: _get(i, 'notificationType.name', ''),
          notificationDeliveryType: _get(i, 'notificationDeliveryNameCsv', ''),
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processNotificationSubscriptionUpdate(i)
              },
              text: 'Edit'
            }
          }
        }
      })
    } else {
      console.log('else determineSubscriptionNotifications...')
    }
  },

  async reload() {
    this.loading = true

    const payload = {
      recipientType: 'homeowner',
      ownerID: _get(this, 'authUser.ownerID', null)
    }

    try {
      this.$store
        .dispatch('alertSubscription/getSubscriptionNotificationList', {
          payload: payload
        })
        .then(() => {
          this.determineSubscriptionNotifications()
        })
    } catch (e) {
      console.debug('error in method for retrieving notification subscription:' + e)
    }

    this.loading = false
  },

  processNotificationSubscriptionUpdate(notificationSubscription) {
    this.toggle = true
    this.selectedSubscriptionNotification = notificationSubscription
  }
}
