<template>
  <PageContentLayoutOnly role="region" :aria-label="$t('accountSecurity.title')">
    <div class="notification headerColor box is-4">
      <div>
        <ChangePassword scope="management" />
      </div>
      <div v-show="false">
        <fieldset>
          <div class="column is-12" style="padding:10px; white-space:nowrap;">
            <label class="label">Additional Authentication</label>
          </div>
        </fieldset>
        <b-button type="button" class="is-medium expanded" expanded :disabled="loading">
          Set Up
        </b-button>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { mapState, mapGetters } from 'vuex'
import ChangePassword from '@/components/ChangePasswordModal'

import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    PageContentLayoutOnly,
    ChangePassword
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  mounted() {
    this.loading = false
  },

  watch: {
    hoaId() {
      this.reload()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  methods,

  i18n: {
    messages: {
      en: { accountSecurity: { title: 'Account Security' } }
    }
  }
}
</script>
